import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    LOGIN_USER_UNVERIFIED,
    CLEAR_AUTH_ERRORS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    CONFIGURATION_AUTOINVITE,
    CONFIGURATION_AUTOINVITE_SUCCESS,
    CONFIGURATION_AUTOINVITE_FAILURE,
    GET_CONFIGURATION_AUTO_INVITE,
    GET_CONFIGURATION_AUTO_INVITE_SUCCESS,
    GET_CONFIGURATION_AUTO_INVITE_FAILURE,
    FETCH_API_TOKEN,
    FETCH_API_TOKEN_SUCCESS,
    FETCH_API_TOKEN_FAILURE,
    FETCH_API_KEY,
    FETCH_API_KEY_SUCCESS,
    FETCH_API_KEY_FAILURE,
    CONFIGURATION_PANELISTSLOTS,
    CONFIGURATION_PANELISTSLOTS_SUCCESS,
    CONFIGURATION_PANELISTSLOTS_FAILURE,
    EMAIL_NOTIFICATION_CONFIG,
    EMAIL_NOTIFICATION_CONFIG_SUCCESS,
    EMAIL_NOTIFICATION_CONFIG_FAILURE,
    GET_EMAIL_NOTIFICATION_CONFIG,
    GET_EMAIL_NOTIFICATION_CONFIG_SUCCESS,
    GET_EMAIL_NOTIFICATION_CONFIG_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    loginLoading: false,
    userData: null,
    authErrorMessage: null,
    configAutoInvite: null,
    botVoice: null,
    apiToken: null,
    apiKey: null,
    twoFactor: false,
    panelistSlotsType: null,
    emailConfig: true,
    feedbackConfig: null,
    mobileDevicePermission: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_API_KEY:
            return { ...state, loading: true };
        case FETCH_API_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                apiKey: action.payload,
            };
        case FETCH_API_KEY_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case FETCH_API_TOKEN:
            return { ...state, loading: true };
        case FETCH_API_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                apiToken: action.payload,
            };
        case FETCH_API_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case LOGIN_USER:
            return { ...state, loading: true, loginLoading: false };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                loginLoading: false,
                userData: action.payload,
            };
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                loginLoading: false,
                authErrorMessage: action.payload,
            };
        case LOGOUT_USER:
            return { ...state, loading: true };
        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case LOGOUT_USER_FAILURE:
            return { ...state, loading: false };
        case CLEAR_AUTH_ERRORS:
            return { ...state, loading: false, authErrorMessage: null };
        case FORGOT_PASSWORD:
            return { ...state, loading: true };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false };
        case RESET_PASSWORD:
            return { ...state, loading: true };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case RESET_PASSWORD_FAILURE:
            return { ...state, loading: false };
        case CONFIGURATION_AUTOINVITE:
            return { ...state, loading: true };
        case CONFIGURATION_AUTOINVITE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CONFIGURATION_AUTOINVITE_FAILURE:
            return { ...state, loading: false };
        case GET_CONFIGURATION_AUTO_INVITE:
            return { ...state, loading: true };
        case GET_CONFIGURATION_AUTO_INVITE_SUCCESS:
            return {
                ...state,
                loading: false,
                configAutoInvite: action?.payload,
                botVoice: action?.payload?.voice,
                twoFactor: action?.payload?.twoFactorEnabled,
                panelistSlotsType: action?.payload?.panelistSlotsType,
                mobileDevicePermission: action?.payload?.mobileDevicePermission,
            };
        case GET_CONFIGURATION_AUTO_INVITE_FAILURE:
            return { ...state, loading: false };

        case CONFIGURATION_PANELISTSLOTS:
            return { ...state, loading: true };
        case CONFIGURATION_PANELISTSLOTS_SUCCESS:
            return {
                ...state,
                loading: false,
                panelistSlotsType: action?.payload?.panelistSlotsType,
            };
        case CONFIGURATION_PANELISTSLOTS_FAILURE:
            return { ...state, loading: false };

        case LOGIN_USER_UNVERIFIED:
            return {
                ...state,
                loading: false,
                emailVerified: action.payload.isVerified,
            };

        case EMAIL_NOTIFICATION_CONFIG:
            return { ...state, loading: true };
        case EMAIL_NOTIFICATION_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case EMAIL_NOTIFICATION_CONFIG_FAILURE:
            return { ...state, loading: false };
        case GET_EMAIL_NOTIFICATION_CONFIG:
            return { ...state, loading: true };
        case GET_EMAIL_NOTIFICATION_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                emailConfig: action?.payload?.incBotEmailNotification,
                feedbackConfig: action?.payload?.feedbackReportPermission,
            };
        case GET_EMAIL_NOTIFICATION_CONFIG_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
