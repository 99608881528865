import { useMemo } from 'react';

export const PageSize = 10;

export const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtension = '.xlsx';

export const razorPaySubscriptionURL = 'https://api.razorpay.com/v1/t/subscriptions/';

export const allProducts = {
    incfeed: '62e3bff795a4940eb3c58909',
    incvid: '62bd95c1acd8ae7dd7ad3eb2',
};

export const timeDurations = [
    { value: 30, label: '30 mins' },
    { value: 45, label: '45 mins' },
    { value: 60, label: '60 mins' },
];

export const GLOBAL = 'global';

export const COMMON_PERMISSION = 'commonPermission';

export const KEY_WORD = {
    GLOBAL: 'global',
    COMMON_PERMISSION: 'commonPermission',
    INCBOT: 'incbot',
    INCSOURCE: 'incsource',
    INCFEED: 'incfeed',
    INCSERVE: 'incserve',
    INCVID: 'incvid',
    INCEXIT: 'incexit',
    EXPORT_TO_EXCEL: 'exportToExcel',
    ROLES_AND_SUBUSERS: 'rolesAndSubusers',
    CREATE: 'create',
    DELETE: 'delete',
    POSITIONS: 'positions',
    INTERVIEWS: 'interviews',
    COMPANY_PROFILE: 'companyProfile',
    CUSTOM_FIELDS: 'customFields',
    SUPPORT: 'support',
    INTEGRATION: 'integration',
    VIEW: 'view',
    ACCOUNTS_AND_BILLING: 'accountsAndBilling',
    FILE_UPLOAD_SUCCESS_MSG: 'File uploaded successfully.',
    PRODUCT_ID: 'productId',
    LOCALHOST: 'localhost',
    INCRUITER_DOMIAN: 'incruiter.com',
    APPLICANTS: 'applicant',
};

export const CANDIDATE_SLOT_PENDING = 'Candidate slot pending';

export const timeSlots = Array.from({ length: (24 * 60) / 5 }, (_, index) => {
    const totalMinutes = index * 5;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
});

export const customTimeSlots = timeSlots.map((time) => ({
    value: time,
    label: formatTime(time),
}));

export const displayTimeSlots = timeSlots.reduce((acc, time) => {
    acc[time] = formatTime(time);
    return acc;
}, {});

function formatTime(time) {
    const hours = parseInt(time.split(':')[0], 10);
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${time.split(':')[1]} ${suffix}`;
}
export const FILE_TYPES = {
    IMAGE: 'image/jpeg, image/png, image/jpg',
    ZIP: 'zip, application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PDFWORD:
        'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword',
};
export const COMMON_PAGE_LIST = [
    '/my-profile',
    '/accounts-billing',
    '/settings',
    '/subuser',
    '/contact-enquiries',
    '/roles',
    '/roles-subuser-list',
    '/product',
    '/plan-pricing',
    '/payment-information',
    '/payment-success',
    '/notification',
    '/support',
];

export const PRODUCTS = {
    INC_BOT: 'IncBot',
    INC_SOURCE: 'IncSource',
    INC_FEED: 'IncFeed',
    INC_SERVE: 'IncServe',
    INC_VID: 'IncVid',
    INC_EXIT: 'IncExit',
};

export const PRODUCT_STATUSES = {
    SUBSCRIBED: 'Subscribed',
};

export const DATE_TIME_FORMAT = {
    HH_MM: 'HH:mm',
    DD_MM_YYYY: 'DD-MM-YYYY',
    YYYY_MM_DD: 'YYYY-MM-DD',
};

export const TOAST_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
};

export const SUCCESS_MSG = {
    FILE_UPLOAD: 'File uploaded successfully.',
};

export const ERROR_MSG = {
    SUPPORT_FILE: 'Uploaded file is not Valid. Only .pdf, .docx, .doc, .png, .jpg, .jpeg files are allowed.',
};
export const SUPPORT_PAGE_FILE_ERROR_MSG =
    'Uploaded file is not Valid. Only .pdf, .docx, .doc, .png, .jpg, .jpeg files are allowed.';
export const EMPTY_FILE_MSG = 'The uploaded file is empty';
export const SEARCH_PLACEHOLDER = 'Search here...';
export const LOCAL_HOST = 'localhost';
export const INCRUITER_DOMAIN = 'incruiter.com';
export const COOKIES_LIST = ['productId', 'userData', 'moveToIncVid'];
export const STATUSES = {
    ACTIVE: 'Active',
    OPEN_POSITION: 'Open Position',
    CLOSED: 'Closed',
    CLOSED_POSITION: 'Closed Position',
    CANCELED: 'Canceled',
    CAUTIOUSLY_RECOMMENDED: 'Cautiously Recommended',
    IN_PROGRESS: 'In Progress',
    INVITED: 'Invited',
    INVITE_SENT: 'Invite Sent',
    NOT_RECOMMENDED: 'Not Recommended',
    PENDING: 'Pending',
    RECOMMENDED: 'Recommended',
    UNATTENDED: 'Unattended',
    NETWORK_DISCONNECTED: 'Network Disconnected',
    REINVITED: 'Reinvited',
    RESUME_REJECTED: 'Resume Rejected',
    INCOMPLETE_DATA: 'Incomplete Data',
    COMPLETED: 'Completed',
};
