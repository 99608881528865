import React, { memo, useEffect, useMemo, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from '../CommonComponent';
import {
    localStorageEncrypt,
    localStorageDecrypt,
    localStorageJsonParseDecrypt,
    mainWebsite,
    incserveUrl,
    hasProductAccess,
    generateUrl,
    getIncserveToken,
    clearCookies,
} from '../../utils/helper';
import appIcon from '../../assets/images/app-toggle-icon.svg';
import { ReactComponent as SupportImg } from '../../assets/images/interrogation.svg';
import { ReactComponent as DownloadImg } from '../../assets/images/chrome-download.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/arrow-right-nostroke.svg';
import { ReactComponent as BackArrowIcon } from '../../assets/images/arrow-back-nostroke.svg';
import { ReactComponent as MPIW } from '../../assets/images/my-products-icon-white.svg';
import defaultProfile from '../../assets/images/Profile_Placeholder.png';
import dropArrow from '../../assets/images/white-drop-arrow.svg';
import Profile from '../../assets/images/mobile-sidebar-imgs/my-profile.svg';
import RoleSubuser from '../../assets/images/mobile-sidebar-imgs/role-subuser.svg';
import AccountsBilling from '../../assets/images/mobile-sidebar-imgs/accounts-billing.svg';
import Settings from '../../assets/images/mobile-sidebar-imgs/settings.svg';
import Support from '../../assets/images/mobile-sidebar-imgs/support.svg';
import Logout from '../../assets/images/mobile-sidebar-imgs/logout.svg';
import Home from '../../assets/images/mobile-sidebar-imgs/home.svg';
import MyInterviews from '../../assets/images/mobile-sidebar-imgs/my-interview.svg';
import MyPositions from '../../assets/images/mobile-sidebar-imgs/my-positions.svg';
import IFP from '../../assets/images/sidebar-menu/edit.svg';
import VIP from '../../assets/images/sidebar-menu/play-alt.svg';
import CRP from '../../assets/images/sidebar-menu/bank.svg';
import IAS from '../../assets/images/sidebar-menu/couple.svg';
import bell from '../../assets/images/mobile-sidebar-imgs/bell.svg';
import closeSidebarImg from '../../assets/images/mobile-sidebar-imgs/mob-side-close.svg';
import MPI from '../../assets/images/my-products-icon.svg';
import GTP from '../../assets/images/go-to-product.svg';
import { noSelectPaths } from '../../utils/helper';
import { getUserPermission } from '../../redux/action';

const Sidebar = memo(
    ({
        children,
        mobSidebar,
        setMobSidebar,
        productLoading,
        productDetails,
        userPermissionList,
        resource,
        mainResource,
        userData,
        activeSidebar,
        setActiveSidebar,
    }) => {
        const [userDetails, setUserDetails] = useState(null);
        const location = useLocation();
        const navigate = useNavigate();
        const loadingMyProfile = useSelector((state) => state?.MyProfile?.loading);
        let productId = localStorageDecrypt('productId') && localStorageJsonParseDecrypt('productId')?.productId;
        const handleSidebar = () => {
            setActiveSidebar(!activeSidebar);
        };
        useEffect(() => {
            setUserDetails(userData);
        }, [userData]);
        useEffect(() => {
            getUserPermission();
        }, []);

        const MyProducts = useMemo(() => {
            if (userPermissionList?.length > 0) {
                return productDetails?.filter(
                    (cardData) =>
                        cardData.status !== 'Unsubscribed' &&
                        hasProductAccess(userPermissionList, cardData?.subdomain.toLowerCase())
                );
            }
            return productDetails?.filter((cardData) => cardData.status !== 'Unsubscribed');
        }, [productDetails, userPermissionList]);

        return (
            <>
                {(productLoading || loadingMyProfile) && <Loader />}
                {window.innerWidth > 992 ? (
                    <div className={`${activeSidebar ? 'sidebar-container' : 'sidebar-container-collapsed'} h-100 `}>
                        <div className="d-flex sidebar-main-container flex-column justify-content-between">
                            <div className="sidebarCollapse" onClick={handleSidebar}>
                                {activeSidebar ? (
                                    <BackArrowIcon className={'sidebar-back-arrow'} />
                                ) : (
                                    <RightArrowIcon className={'sidebar-right-arrow'} />
                                )}
                            </div>
                            {!activeSidebar && (
                                <div className="sidebar-main collapse-padding d-flex flex-column">
                                    <div className="mt-4 d-flex flex-column align-items-center">
                                        <a
                                            href="/product"
                                            className={window.location.pathname === '/product' ? 'disabled-a' : ''}
                                        >
                                            <div
                                                className={`${window.location.pathname === '/product' ? 'active-header' : ''} sidebar-collapse-header`}
                                            >
                                                <MPIW />
                                            </div>
                                        </a>
                                        <div className="division-div"></div>
                                    </div>
                                    <div className="d-flex flex-column gap-10 mt-2">
                                        {MyProducts &&
                                            MyProducts?.length > 0 &&
                                            MyProducts?.map((product, index) =>
                                                product?.subdomain === 'InCServe' ? (
                                                    getIncserveToken && (
                                                        <a
                                                            href={`${incserveUrl}/employer-callback`}
                                                            className={`d-flex align-items-center justify-content-center flex-column gap-10`}
                                                            key={index}
                                                            title="Go to Dashboard"
                                                            onClick={() =>
                                                                localStorageEncrypt(
                                                                    'productId',
                                                                    JSON.stringify({
                                                                        productId: product?._id,
                                                                        isActive: product?.status,
                                                                        productSubDomain: product?.subdomain,
                                                                    })
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    productId === product?._id &&
                                                                    noSelectPaths.every(
                                                                        (path) => window.location.pathname !== path
                                                                    )
                                                                        ? `bg-white product-icon-container d-center`
                                                                        : ''
                                                                }
                                                            >
                                                                <img src={product?.icon} alt="Product Icon" />
                                                            </div>
                                                            <span className="f-10">{product?.name}</span>
                                                        </a>
                                                    )
                                                ) : product?.subdomain === 'InCBot' ? (
                                                    <a
                                                        href={`${generateUrl('incbot')}/dashboard`}
                                                        className={`d-flex align-items-center justify-content-center flex-column gap-10`}
                                                        key={index}
                                                        title="Go to Dashboard"
                                                        onClick={() =>
                                                            localStorageEncrypt(
                                                                'productId',
                                                                JSON.stringify({
                                                                    productId: product?._id,
                                                                    isActive: product?.status,
                                                                    productSubDomain: product?.subdomain,
                                                                })
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                productId === product?._id &&
                                                                noSelectPaths.every(
                                                                    (path) => window.location.pathname !== path
                                                                )
                                                                    ? `bg-white product-icon-container d-center`
                                                                    : ''
                                                            }
                                                        >
                                                            <img src={product?.icon} alt="Product Icon" />
                                                        </div>
                                                        <span className="f-10">{product?.name}</span>
                                                    </a>
                                                ) : (
                                                    <Link
                                                        to="/dashboard"
                                                        key={index}
                                                        className={`d-flex align-items-center justify-content-center flex-column gap-10`}
                                                        onClick={() =>
                                                            localStorageEncrypt(
                                                                'productId',
                                                                JSON.stringify({
                                                                    productId: product?._id,
                                                                    isActive: product?.status,
                                                                    productSubDomain: product?.subdomain,
                                                                })
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                productId === product?._id &&
                                                                noSelectPaths.every(
                                                                    (path) => window.location.pathname !== path
                                                                )
                                                                    ? `bg-white product-icon-container d-center`
                                                                    : ''
                                                            }
                                                        >
                                                            <img src={product?.icon} alt="Product Icon" />
                                                        </div>
                                                        <span className="f-10">{product?.name}</span>
                                                    </Link>
                                                )
                                            )}
                                    </div>
                                </div>
                            )}
                            {activeSidebar && (
                                <div className={`active-sidebar-main`}>
                                    <div className="my-services-container">
                                        <a
                                            href="/product"
                                            className={window.location.pathname === '/product' ? 'disabled-a' : ''}
                                        >
                                            <div
                                                className={`${window.location.pathname === '/product' ? 'active-header' : ''} sidebar-header`}
                                            >
                                                <MPIW />
                                                <div className="title f-sh f-bol">Product Dashboard</div>
                                            </div>
                                        </a>
                                        <div className="division-div"></div>
                                        {MyProducts && MyProducts?.length > 0 && (
                                            <>
                                                <div className="go-to-product f-bol">
                                                    <span className="ms-2">Go to Product</span>
                                                    <img className="gtp-img" src={GTP} />
                                                </div>
                                                <div className="division-div"></div>
                                                {MyProducts?.map((product, index) =>
                                                    product?.subdomain === 'InCServe' ? (
                                                        getIncserveToken && (
                                                            <a
                                                                href={`${incserveUrl}/employer-callback`}
                                                                className={`services  rounded f-l f-bol ${
                                                                    productId === product?._id &&
                                                                    noSelectPaths.every(
                                                                        (path) => window.location.pathname !== path
                                                                    )
                                                                        ? `active`
                                                                        : ''
                                                                }`}
                                                                key={index}
                                                                title="Go to Dashboard"
                                                                onClick={() =>
                                                                    localStorageEncrypt(
                                                                        'productId',
                                                                        JSON.stringify({
                                                                            productId: product?._id,
                                                                            isActive: product?.status,
                                                                            productSubDomain: product?.subdomain,
                                                                        })
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={product?.icon}
                                                                    alt="Product Icon"
                                                                    className="ms-2"
                                                                />
                                                                {product?.name}
                                                            </a>
                                                        )
                                                    ) : product?.subdomain === 'InCBot' ? (
                                                        <a
                                                            href={`${generateUrl('incbot')}/dashboard`}
                                                            className={`services  rounded f-l f-bol ${
                                                                productId === product?._id &&
                                                                noSelectPaths.every(
                                                                    (path) => window.location.pathname !== path
                                                                )
                                                                    ? `active`
                                                                    : ''
                                                            }`}
                                                            key={index}
                                                            title="Go to Dashboard"
                                                            onClick={() =>
                                                                localStorageEncrypt(
                                                                    'productId',
                                                                    JSON.stringify({
                                                                        productId: product?._id,
                                                                        isActive: product?.status,
                                                                        productSubDomain: product?.subdomain,
                                                                    })
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={product?.icon}
                                                                alt="Product Icon"
                                                                className="ms-2"
                                                            />
                                                            {product?.name}
                                                        </a>
                                                    ) : (
                                                        <Link
                                                            to="/dashboard"
                                                            key={index}
                                                            className={`services  rounded f-l f-bol ${
                                                                productId === product?._id &&
                                                                noSelectPaths.every(
                                                                    (path) => window.location.pathname !== path
                                                                )
                                                                    ? `active`
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                localStorageEncrypt(
                                                                    'productId',
                                                                    JSON.stringify({
                                                                        productId: product?._id,
                                                                        isActive: product?.status,
                                                                        productSubDomain: product?.subdomain,
                                                                    })
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={product?.icon}
                                                                alt="Product Icon"
                                                                className="ms-2"
                                                            />
                                                            {product?.name}
                                                        </Link>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="active-sidebar-downdiv">
                                <div className={`${activeSidebar ? 'interogation-main' : 'interogation-main-active'}`}>
                                    {localStorageDecrypt('productId') &&
                                    localStorageJsonParseDecrypt('productId')?.productSubDomain === 'InCFeed' &&
                                    noSelectPaths.every((path) => window.location.pathname !== path) ? (
                                        <a
                                            href="https://chrome.google.com/webstore/detail/incfeed/hbkjfklhbhbobnioodpnkcfpabljgipe"
                                            title="Chrome Extension"
                                            className={`rounded ${
                                                activeSidebar
                                                    ? 'support-width1 d-flex align-items-center'
                                                    : 'd-center p-2'
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <DownloadImg />
                                            {activeSidebar && (
                                                <span className="f-14 f-sem-bol p-2">Chrome Extension</span>
                                            )}
                                        </a>
                                    ) : (
                                        <></>
                                    )}
                                    <div
                                        className={`rounded ${
                                            activeSidebar
                                                ? 'support-width d-flex align-items-center cursor-pointer'
                                                : 'd-center p-2'
                                        }`}
                                        onClick={() => {
                                            navigate('/support');
                                        }}
                                    >
                                        <SupportImg className="cursor-pointer" />
                                        {activeSidebar && <span className="f-14 f-sem-bol p-2">Support</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <MobileSidebar mobSidebar={mobSidebar} setMobSidebar={setMobSidebar} />
                )}
                <div className={`page-container flex-1 h-100 ${activeSidebar ? 'page-shrink' : 'page-expand'}`}>
                    {children}
                </div>
            </>
        );
    }
);

const MobileSidebar = ({ mobSidebar, setMobSidebar }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userObject, setUserObject] = useState(null);
    const [companyObject, setCompanyObject] = useState(null);
    const [showNavItem, setShowNavItem] = useState(false);
    var pageAccess = [
        '/position',
        '/add-applicant',
        '/my-interviews',
        '/my-positions',
        '/dashboard',
        '/create-panelist',
    ];
    useEffect(() => {
        setCompanyObject(localStorageJsonParseDecrypt('companyData'));
        setUserObject(localStorageJsonParseDecrypt('userData'));
    }, []);
    const handleLogout = () => {
        localStorage.clear();
        clearCookies();
        window.location.replace(`${mainWebsite}/employer/login`);
    };
    let productId = localStorageDecrypt('productId') && localStorageJsonParseDecrypt('productId')?.productId;
    useEffect(() => {
        window.addEventListener('LOGO_URL_CHANGE', () => {
            setCompanyObject(localStorageJsonParseDecrypt('companyData'));
            setUserObject(localStorageJsonParseDecrypt('userData'));
        });
        window.addEventListener('COMPANY_DETAILS_CHANGE', () => {
            setCompanyObject(localStorageJsonParseDecrypt('companyData'));
            setUserObject(localStorageJsonParseDecrypt('userData'));
        });
    });
    const menuList = [
        {
            img: Profile,
            name: 'My Profile',
            path: '/my-profile',
        },
        {
            img: RoleSubuser,
            name: 'Role / Sub User',
            path: '/roles-subuser-list',
        },
        {
            img: AccountsBilling,
            name: 'Accounts & Billing',
            path: '/my-profile',
        },
        {
            img: Settings,
            name: 'Settings',
            path: '/settings',
        },
        {
            img: Support,
            name: 'Support',
            path: '/support',
        },
        {
            img: Logout,
            name: 'Log Out',
            path: '/',
        },
    ];
    let links = [];
    if (pageAccess?.indexOf(location?.pathname) > -1) {
        links = [
            {
                img: Home,
                name: 'Home',
                path: '/dashboard',
            },
            {
                img: MyPositions,
                name: 'Create Position',
                path: '/position',
            },
            {
                img: MyInterviews,
                name: 'Add Candidate',
                path: '/add-applicant',
            },
            {
                img: MyPositions,
                name: 'My Positions',
                path: '/my-positions',
            },
            {
                img: MyInterviews,
                name: 'My Interviews',
                path: '/my-interviews',
            },
        ];
    } else {
        links = [
            {
                img: Home,
                name: 'Go to Dashboard',
                path: productId ? '/dashboard' : '/product',
            },
        ];
    }

    const services = [
        {
            img: IFP,
            name: 'Interview Feedback Portal',
        },
        {
            img: VIP,
            name: 'Video Interview Platform',
        },
        {
            img: CRP,
            name: 'Video Interview Platform',
        },
        {
            img: IAS,
            name: 'Interview as a Service',
        },
    ];
    return (
        <>
            <div className={`mobile-sidebar ${mobSidebar ? 'side-open' : 'side-close'}`}>
                <img
                    src={closeSidebarImg}
                    onClick={() => setMobSidebar(false)}
                    alt="close-side"
                    className="close-side-img"
                />
                <div className="sidebar-over">
                    <div className="profile-noti-box d-flex align-items-center justify-content-between">
                        <div className="profile-container">
                            <img
                                src={userObject?.photo ? userObject?.photo : defaultProfile}
                                alt="profile-img"
                                className="profile-img"
                            />
                        </div>
                        <div className="notification-container" onClick={() => navigate('/notification')}>
                            <img src={bell} alt="bell" className="notify-bell" />
                            <span className="notify"></span>
                        </div>
                    </div>
                    <div className="profile-box d-flex justify-content-between">
                        <div className="name-id-box d-flex flex-column">
                            <span className="name f-bol">{companyObject?.name}</span>
                            <span className="id f-reg">{userObject?.code}</span>
                        </div>
                        <img
                            onClick={() => setShowNavItem(!showNavItem)}
                            src={dropArrow}
                            alt="arrow"
                            className="drop-arrow"
                            style={{ transform: `${showNavItem ? 'rotate(90deg)' : ''}` }}
                        />
                    </div>
                    {showNavItem && (
                        <ul className={`f-med profile-contents`}>
                            {menuList?.map((data, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        if (data?.name === 'Log Out') {
                                            handleLogout();
                                            navigate(data?.path);
                                        } else {
                                            navigate(data?.path);
                                        }
                                    }}
                                >
                                    <img src={data?.img} alt="profile-menu-img" className="prfile-menu-img" />
                                    <span className="profile-menu-name">{data?.name}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className="mob-divider"></div>
                    <ul className="quick-link-box f-med">
                        {links?.map((data, index) => (
                            <li key={index} onClick={() => navigate(data?.path)}>
                                <img src={data?.img} alt="profile-menu-img" className="prfile-menu-img" />
                                <span className="profile-menu-name">{data?.name}</span>
                            </li>
                        ))}
                    </ul>
                    <div className="mob-divider"></div>
                    <div className="f-sem-bol services">Services</div>
                    <ul className="mob-services  f-med">
                        {services?.map((data, index) => (
                            <li key={index}>
                                <img src={data?.img} alt="profile-menu-img" className="prfile-menu-img" />
                                <span className="profile-menu-name">{data?.name}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
