export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_UNVERIFIED = 'LOGIN_USER_UNVERIFIED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';

export const FETCH_API_TOKEN = 'FETCH_API_TOKEN';
export const FETCH_API_TOKEN_SUCCESS = 'FETCH_API_TOKEN_SUCCESS';
export const FETCH_API_TOKEN_FAILURE = 'FETCH_API_TOKEN_FAILURE';

export const TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH';
export const TWO_FACTOR_AUTH_SUCCESS = 'TWO_FACTOR_AUTH_SUCCESS';
export const TWO_FACTOR_AUTH_FAILURE = 'TWO_FACTOR_AUTH_FAILURE';

export const SSO_INTEGRATION = 'SSO_INTEGRATION';
export const SSO_INTEGRATION_SUCCESS = 'SSO_INTEGRATION_SUCCESS';
export const SSO_INTEGRATION_FAILURE = 'SSO_INTEGRATION_FAILURE';

export const GET_ALL_PERMISSION = 'GET_ALL_PERMISSION';
export const GET_ALL_PERMISSION_SUCCESS = 'GET_ALL_PERMISSION_SUCCESS';
export const GET_ALL_PERMISSION_FAILURE = 'GET_ALL_PERMISSION_FAILURE';

export const FETCH_API_KEY = 'FETCH_API_KEY';
export const FETCH_API_KEY_SUCCESS = 'FETCH_API_KEY_SUCCESS';
export const FETCH_API_KEY_FAILURE = 'FETCH_API_KEY_FAILURE';

export const FORGOT_PASSWORD_TOKEN_VERIFY = 'FORGOT_PASSWORD_TOKEN_VERIFY';
export const FORGOT_PASSWORD_TOKEN_VERIFY_SUCCESS = 'FORGOT_PASSWORD_TOKEN_VERIFY_SUCCESS';
export const FORGOT_PASSWORD_TOKEN_VERIFY_FAILURE = 'FORGOT_PASSWORD_TOKEN_VERIFY_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const CONFIGURATION_AUTOINVITE = 'CONFIGURATION_AUTOINVITE';
export const CONFIGURATION_AUTOINVITE_SUCCESS = 'CONFIGURATION_AUTOINVITE_SUCCESS';
export const CONFIGURATION_AUTOINVITE_FAILURE = 'CONFIGURATION_AUTOINVITE_FAILURE';

export const TOKEN_VERIFY = 'TOKEN_VERIFY';
export const TOKEN_VERIFY_SUCCESS = 'TOKEN_VERIFY_SUCCESS';
export const TOKEN_VERIFY_FAILURE = 'TOKEN_VERIFY_FAILURE';
export const CLEAR_SIGNUP_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';

export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_FAILURE = 'GET_COMPANY_DETAILS_FAILURE';

export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export const UPDATE_COMPANY_DETAILS_SUCCESS = 'UPDATE_COMPANY_DETAILS_SUCCESS';
export const UPDATE_COMPANY_DETAILS_FAILURE = 'UPDATE_COMPANY_DETAILS_FAILURE';

export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const GET_USER_INFORMATION_SUCCESS = 'GET_USER_INFORMATION_SUCCESS';
export const GET_USER_INFORMATION_FAILURE = 'GET_USER_INFORMATION_FAILURE';

export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';
export const UPDATE_USER_INFORMATION_SUCCESS = 'UPDATE_USER_INFORMATION_SUCCESS';
export const UPDATE_USER_INFORMATION_FAILURE = 'UPDATE_USER_INFORMATION_FAILURE';

export const GET_INDUSTRY_TYPE = 'GET_INDUSTRY_TYPE';
export const GET_INDUSTRY_TYPE_SUCCESS = 'GET_INDUSTRY_TYPE_SUCCESS';
export const GET_INDUSTRY_TYPE_FAILURE = 'GET_INDUSTRY_TYPE_FAILURE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const GET_DOMAIN = 'GET_DOMAIN';
export const GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS';
export const GET_DOMAIN_FAILURE = 'GET_DOMAIN_FAILURE';

export const GET_MANDATORY_SKILL = 'GET_MANDATORY_SKILL';
export const GET_MANDATORY_SKILL_SUCCESS = 'GET_MANDATORY_SKILL_SUCCESS';
export const GET_MANDATORY_SKILL_FAILURE = 'GET_MANDATORY_SKILL_FAILURE';

export const GET_OPTIONAL_SKILL = 'GET_OPTIONAL_SKILL';
export const GET_OPTIONAL_SKILL_SUCCESS = 'GET_OPTIONAL_SKILL_SUCCESS';
export const GET_OPTIONAL_SKILL_FAILURE = 'GET_OPTIONAL_SKILL_FAILURE';

export const GET_SCREENING_QUESTIONS = 'GET_SCREENING_QUESTIONS';
export const GET_SCREENING_QUESTIONS_SUCCESS = 'GET_SCREENING_QUESTIONS_SUCCESS';
export const GET_SCREENING_QUESTIONS_FAILURE = 'GET_SCREENING_QUESTIONS_FAILURE';

export const DELETE_SCREENING_QUESTIONS = 'DELETE_SCREENING_QUESTIONS';
export const DELETE_SCREENING_QUESTIONS_SUCCESS = 'DELETE_SCREENING_QUESTIONS_SUCCESS';
export const DELETE_SCREENING_QUESTIONS_FAILURE = 'DELETE_SCREENING_QUESTIONS_FAILURE';

export const CREATE_OR_UPDATE_POSITION = 'CREATE_OR_UPDATE_POSITION';
export const CREATE_OR_UPDATE_POSITION_SUCCESS = 'CREATE_OR_UPDATE_POSITION_SUCCESS';
export const CREATE_OR_UPDATE_POSITION_FAILURE = 'CREATE_OR_UPDATE_POSITION_FAILURE';

export const POSITIONAUTHERRORS = 'POSITIONAUTHERRORS';

export const DELETE_POSITION = 'DELETE_POSITION';
export const DELETE_POSITION_SUCCESS = 'DELETE_POSITION_SUCCESS';
export const DELETE_POSITION_FAILURE = 'DELETE_POSITION_FAILURE';

export const GET_SHORTLISTED_CANDIDATES = 'GET_SHORTLISTED_CANDIDATES';
export const GET_SHORTLISTED_CANDIDATES_SUCCESS = 'GET_SHORTLISTED_CANDIDATES_SUCCESS';
export const GET_SHORTLISTED_CANDIDATES_FAILURE = 'GET_SHORTLISTED_CANDIDATES_FAILURE';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE';

export const GET_PLANS_AND_PRICING = 'GET_PLANS_AND_PRICING';
export const GET_PLANS_AND_PRICING_SUCCESS = 'GET_PLANS_AND_PRICING_SUCCESS';
export const GET_PLANS_AND_PRICING_FAILURE = 'GET_PLANS_AND_PRICING_FAILURE';

export const UPDATE_PAYMENT_INFORMATION = 'UPDATE_PAYMENT_INFORMATION';
export const UPDATE_PAYMENT_INFORMATION_SUCCESS = 'UPDATE_PAYMENT_INFORMATION_SUCCESS';
export const UPDATE_PAYMENT_INFORMATION_FAILURE = 'UPDATE_PAYMENT_INFORMATION_FAILURE';

export const GET_ORDER_LIST = 'GET_ORDER_LIST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_FAILURE = 'GET_ORDER_LIST_FAILURE';

export const GET_BILLING_INFORMATION = 'GET_BILLING_INFORMATION';
export const GET_BILLING_INFORMATION_SUCCESS = 'GET_BILLING_INFORMATION_SUCCESS';
export const GET_BILLING_INFORMATION_FAILURE = 'GET_BILLING_INFORMATION_FAILURE';

export const GET_POSITION_LIST = 'GET_POSITION_LIST';
export const GET_POSITION_LIST_SUCCESS = 'GET_POSITION_LIST_SUCCESS';
export const GET_POSITION_LIST_FAILURE = 'GET_POSITION_LIST_FAILURE';

export const GET_POSITION = 'GET_POSITION';
export const GET_POSITION_SUCCESS = 'GET_POSITION_SUCCESS';
export const GET_POSITION_FAILURE = 'GET_POSITION_FAILURE';

export const CHANGE_POSITION_STATUS = 'CHANGE_POSITION_STATUS';
export const CHANGE_POSITION_STATUS_SUCCESS = 'CHANGE_POSITION_STATUS_SUCCESS';
export const CHANGE_POSITION_STATUS_FAILURE = 'CHANGE_POSITION_STATUS_FAILURE';

export const GET_DROPDOWN_POSITION = 'GET_DROPDOWN_POSITION';
export const GET_DROPDOWN_POSITION_SUCCESS = 'GET_DROPDOWN_POSITION_SUCCESS';
export const GET_DROPDOWN_POSITION_FAILURE = 'GET_DROPDOWN_POSITION_FAILURE';

export const POST_INTERVIEW = 'POST_INTERVIEW';
export const POST_INTERVIEW_SUCCESS = 'POST_INTERVIEW_SUCCESS';
export const POST_INTERVIEW_FAILURE = 'POST_INTERVIEW_FAILURE';

export const POST_BULK_INTERVIEW = 'POST_BULK_INTERVIEW';
export const POST_BULK_INTERVIEW_SUCCESS = 'POST_BULK_INTERVIEW_SUCCESS';
export const POST_BULK_INTERVIEW_FAILURE = 'POST_BULK_INTERVIEW_FAILURE';

export const GET_INTERVIEW = 'GET_INTERVIEW';
export const GET_INTERVIEW_SUCCESS = 'GET_INTERVIEW_SUCCESS';
export const GET_INTERVIEW_FAILURE = 'GET_INTERVIEW_FAILURE';

export const REQUEST_FEEDBACK = 'REQUEST_FEEDBACK';
export const REQUEST_FEEDBACK_SUCCESS = 'REQUEST_FEEDBACK_SUCCESS';
export const REQUEST_FEEDBACK_FAILURE = 'REQUEST_FEEDBACK_FAILURE';

export const GET_INTERVIEW_STAT = 'GET_INTERVIEW_STAT';
export const GET_INTERVIEW_STAT_SUCCESS = 'GET_INTERVIEW_STAT_SUCCESS';
export const GET_INTERVIEW_STAT_FAILURE = 'GET_INTERVIEW_STAT_FAILURE';

export const GET_POSITION_STAT = 'GET_POSITION_STAT';
export const GET_POSITION_STAT_SUCCESS = 'GET_POSITION_STAT_SUCCESS';
export const GET_POSITION_STAT_FAILURE = 'GET_POSITION_STAT_FAILURE';

export const GET_CANDIDATE_STAT = 'GET_CANDIDATE_STAT';
export const GET_CANDIDATE_STAT_SUCCESS = 'GET_CANDIDATE_STAT_SUCCESS';
export const GET_CANDIDATE_STAT_FAILURE = 'GET_CANDIDATE_STAT_FAILURE';

export const GET_POSITION_STAT_FOR_BAR_GRAPH = 'GET_POSITION_STAT_FOR_BAR_GRAPH';
export const GET_POSITION_STAT_FOR_BAR_GRAPH_SUCCESS = 'GET_POSITION_STAT_FOR_BAR_GRAPH_SUCCESS';
export const GET_POSITION_STAT_FOR_BAR_GRAPH_FAILURE = 'GET_POSITION_STAT_FOR_BAR_GRAPH_FAILURE';

export const GET_INTERVIEW_BY_ID_FOR_POSITION = 'GET_INTERVIEW_BY_ID_FOR_POSITION';
export const GET_INTERVIEW_BY_ID_FOR_POSITION_SUCCESS = 'GET_INTERVIEW_BY_ID_FOR_POSITION_SUCCESS';
export const GET_INTERVIEW_BY_ID_FOR_POSITION_FAILURE = 'GET_INTERVIEW_BY_ID_FOR_POSITION_FAILURE';

export const GET_INTERVIEW_BY_ID = 'GET_INTERVIEW_BY_ID';
export const GET_INTERVIEW_BY_ID_SUCCESS = 'GET_INTERVIEW_BY_ID_SUCCESS';
export const GET_INTERVIEW_BY_ID_FAILURE = 'GET_INTERVIEW_BY_ID_FAILURE';

export const GET_CANDIDATE_DETAILS_USING_RESUME = 'GET_CANDIDATE_DETAILS_USING_RESUME';
export const GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS = 'GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS';
export const GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE = 'GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE';

export const UPDATE_INTERVIEW_STATUS = 'UPDATE_INTERVIEW_STATUS';
export const UPDATE_INTERVIEW_STATUS_SUCCESS = 'UPDATE_INTERVIEW_STATUS_SUCCESS';
export const UPDATE_INTERVIEW_STATUS_FAILURE = 'UPDATE_INTERVIEW_STATUS_FAILURE';

export const GET_PANELIST_CALENDAR_REQUEST = 'GET_PANELIST_CALENDAR_REQUEST';
export const GET_PANELIST_CALENDAR_REQUEST_SUCCESS = 'GET_PANELIST_CALENDAR_REQUEST_SUCCESS';
export const GET_PANELIST_CALENDAR_REQUEST_FAILURE = 'GET_PANELIST_CALENDAR_REQUEST_FAILURE';

export const GET_CANDIDATE_CALENDAR_REQUEST = 'GET_CANDIDATE_CALENDAR_REQUEST';
export const GET_CANDIDATE_CALENDAR_REQUEST_SUCCESS = 'GET_CANDIDATE_CALENDAR_REQUEST_SUCCESS';
export const GET_CANDIDATE_CALENDAR_REQUEST_FAILURE = 'GET_CANDIDATE_CALENDAR_REQUEST_FAILURE';

export const GET_FEEDBACK = 'GET_FEEDBACK';
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS';
export const GET_FEEDBACK_FAILURE = 'GET_FEEDBACK_FAILURE';

export const GET_FEEDBACK_REPORT = 'GET_FEEDBACK_REPORT';
export const GET_FEEDBACK_REPORT_SUCCESS = 'GET_FEEDBACK_REPORT_SUCCESS';
export const GET_FEEDBACK_REPORT_FAILURE = 'GET_FEEDBACK_REPORT_FAILURE';

export const CREATE_CALENDAR_INVITATION = 'CREATE_CALENDAR_INVITATION';
export const CREATE_CALENDAR_INVITATION_SUCCESS = 'CREATE_CALENDAR_INVITATION_SUCCESS';
export const CREATE_CALENDAR_INVITATION_FAILURE = 'CREATE_CALENDAR_INVITATION_FAILURE';

export const CONTINUE_CALENDAR_INVITATION = 'CONTINUE_CALENDAR_INVITATION';
export const CONTINUE_CALENDAR_INVITATION_SUCCESS = 'CONTINUE_CALENDAR_INVITATION_SUCCESS';
export const CONTINUE_CALENDAR_INVITATION_FAILURE = 'CONTINUE_CALENDAR_INVITATION_FAILURE';

export const CHECK_CANDIDATE_EXISTS = 'CHECK_CANDIDATE_EXISTS';
export const CHECK_CANDIDATE_EXISTS_SUCCESS = 'CHECK_CANDIDATE_EXISTS_SUCCESS';
export const CHECK_CANDIDATE_EXISTS_FAILURE = 'CHECK_CANDIDATE_EXISTS_FAILURE';

export const CREATE_JD = 'CREATE_JD';
export const CREATE_JD_SUCCESS = 'CREATE_JD_SUCCESS';
export const CREATE_JD_FAILURE = 'CREATE_JD_FAILURE';

export const COMPARE_JD_CV = 'COMPARE_JD_CV';
export const COMPARE_JD_CV_SUCCESS = 'COMPARE_JD_CV_SUCCESS';
export const COMPARE_JD_CV_FAILURE = 'COMPARE_JD_CV_FAILURE';

export const GET_GUEST_LIST = 'GET_GUEST_LIST';
export const GET_GUEST_LIST_SUCCESS = 'GET_GUEST_LIST_SUCCESS';
export const GET_GUEST_LIST_FAILURE = 'GET_GUEST_LIST_FAILURE';

export const ADD_OR_EDIT_SUB_USER = 'ADD_OR_EDIT_SUB_USER';
export const ADD_OR_EDIT_SUB_USER_SUCCESS = 'ADD_OR_EDIT_SUB_USER_SUCCESS';
export const ADD_OR_EDIT_SUB_USER_FAILURE = 'ADD_OR_EDIT_SUB_USER_FAILURE';

export const GET_SUB_USER = 'GET_SUB_USER';
export const GET_SUB_USER_SUCCESS = 'GET_SUB_USER_SUCCESS';
export const GET_SUB_USER_FAILURE = 'GET_SUB_USER_FAILURE';

export const DELETE_SUB_USER = 'DELETE_SUB_USER';
export const DELETE_SUB_USER_SUCCESS = 'DELETE_SUB_USER_SUCCESS';
export const DELETE_SUB_USER_FAILURE = 'DELETE_SUB_USER_FAILURE';

export const ACTIVE_INACTIVE_SUBUSER = 'ACTIVE_INACTIVE_SUBUSER';
export const ACTIVE_INACTIVE_SUBUSER_SUCCESS = 'ACTIVE_INACTIVE_SUBUSER_SUCCESS';
export const ACTIVE_INACTIVE_SUBUSER_FAILURE = 'ACTIVE_INACTIVE_SUBUSER_FAILURE';

export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_FAILURE = 'GET_ALL_ROLES_FAILURE';

export const GET_ROLE_BY_ID = 'GET_ROLE_BY_ID';
export const GET_ROLE_BY_ID_SUCCESS = 'GET_ROLE_BY_ID_SUCCESS';
export const GET_ROLE_BY_ID_FAILURE = 'GET_ROLE_BY_ID_FAILURE';

export const ADD_OR_EDIT_ROLE = 'ADD_OR_EDIT_ROLE';
export const ADD_OR_EDIT_ROLE_SUCCESS = 'ADD_OR_EDIT_ROLE_SUCCESS';
export const ADD_OR_EDIT_ROLE_FAILURE = 'ADD_OR_EDIT_ROLE_FAILURE';

export const DELETE_ROLES = 'DELETE_ROLES';
export const DELETE_ROLES_SUCCESS = 'DELETE_ROLES_SUCCESS';
export const DELETE_ROLES_FAILURE = 'DELETE_ROLES_FAILURE';

export const ACTIVE_INACTIVE_ROLE = 'ACTIVE_INACTIVE_ROLE';
export const ACTIVE_INACTIVE_ROLE_SUCCESS = 'ACTIVE_INACTIVE_ROLE_SUCCESS';
export const ACTIVE_INACTIVE_ROLE_FAILURE = 'ACTIVE_INACTIVE_ROLE_FAILURE';

export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE';

export const GET_USER_PERMISSION = 'GET_USER_PERMISSION';
export const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS';
export const GET_USER_PERMISSION_FAILURE = 'GET_USER_PERMISSION_FAILURE';

export const SUPPORT_REQUEST = 'SUPPORT_REQUEST';
export const SUPPORT_REQUEST_SUCCESS = 'SUPPORT_REQUEST_SUCCESS';
export const SUPPORT_REQUEST_FAILURE = 'SUPPORT_REQUEST_FAILURE';

export const CALL_BACK_REQUEST = 'CALL_BACK_REQUEST';
export const CALL_BACK_REQUEST_SUCCESS = 'CALL_BACK_REQUEST_SUCCESS';
export const CALL_BACK_REQUEST_FAILURE = 'CALL_BACK_REQUEST_FAILURE';

export const GET_SUPPORT_TICKET = 'GET_SUPPORT_TICKET';
export const GET_SUPPORT_TICKET_SUCCESS = 'GET_SUPPORT_TICKET_SUCCESS';
export const GET_SUPPORT_TICKET_FAILURE = 'GET_SUPPORT_TICKET_FAILURE';

export const CLOSE_SUPPORT_TICKET = 'CLOSE_SUPPORT_TICKET';
export const CLOSE_SUPPORT_TICKET_SUCCESS = 'CLOSE_SUPPORT_TICKET_SUCCESS';
export const CLOSE_SUPPORT_TICKET_FAILURE = 'CLOSE_SUPPORT_TICKET_FAILURE';

export const GET_INTERVIEW_MONTHLY_STATS = 'GET_INTERVIEW_MONTHLY_STATS';
export const GET_INTERVIEW_MONTHLY_STATS_SUCCESS = 'GET_INTERVIEW_MONTHLY_STATS_SUCCESS';
export const GET_INTERVIEW_MONTHLY_STATS_FAILURE = 'GET_INTERVIEW_MONTHLY_STATS_FAILURE';
export const GET_SUBUSER_INTERVIEW_MONTHLY_STATS = 'GET_SUBUSER_INTERVIEW_MONTHLY_STATS';
export const GET_SUBUSER_INTERVIEW_MONTHLY_STATS_SUCCESS = 'GET_SUBUSER_INTERVIEW_MONTHLY_STATS_SUCCESS';
export const GET_SUBUSER_INTERVIEW_MONTHLY_STATS_FAILURE = 'GET_SUBUSER_INTERVIEW_MONTHLY_STATS_FAILURE';

export const GET_EVENT_LIST = 'GET_EVENT_LIST';
export const GET_EVENT_LIST_SUCCESS = 'GET_EVENT_LIST_SUCCESS';
export const GET_EVENT_LIST_FAILURE = 'GET_EVENT_LIST_FAILURE';

export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILURE = 'GET_PAYMENT_METHOD_FAILURE';

export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHOD_FAILURE';

export const GET_SUBSCRIPTION_LIST = 'GET_SUBSCRIPTION_LIST';
export const GET_SUBSCRIPTION_LIST_SUCCESS = 'GET_SUBSCRIPTION_LIST_SUCCESS';
export const GET_SUBSCRIPTION_LIST_FAILURE = 'GET_SUBSCRIPTION_LIST_FAILURE';

export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_FAILURE = 'GET_NOTIFICATION_LIST_FAILURE';

export const READ_NOTIFICATION_LIST = 'READ_NOTIFICATION_LIST';
export const READ_NOTIFICATION_LIST_SUCCESS = 'READ_NOTIFICATION_LIST_SUCCESS';
export const READ_NOTIFICATION_LIST_FAILURE = 'READ_NOTIFICATION_LIST_FAILURE';

export const CLEAR_NOTIFICATION_LIST = 'CLEAR_NOTIFICATION_LIST';
export const CLEAR_NOTIFICATION_LIST_SUCCESS = 'CLEAR_NOTIFICATION_LIST_SUCCESS';
export const CLEAR_NOTIFICATION_LIST_FAILURE = 'CLEAR_NOTIFICATION_LIST_FAILURE';

export const POST_BULK_PANELIST = 'POST_BULK_PANELIST';
export const POST_BULK_PANELIST_SUCCESS = 'POST_BULK_PANELIST_SUCCESS';
export const POST_BULK_PANELIST_FAILURE = 'POST_BULK_PANELIST_FAILURE';

export const NOTIFY_PANELIST = 'NOTIFY_PANELIST';
export const NOTIFY_PANELIST_SUCCESS = 'NOTIFY_PANELIST_SUCCESS';
export const NOTIFY_PANELIST_FAILURE = 'NOTIFY_PANELIST_FAILURE';

export const ADD_PANELIST = 'ADD_PANELIST';
export const ADD_PANELIST_SUCCESS = 'ADD_PANELIST_SUCCESS';
export const ADD_PANELIST_FAILURE = 'ADD_PANELIST_FAILURE';

export const GET_PANELIST = 'GET_PANELIST';
export const GET_PANELIST_SUCCESS = 'GET_PANELIST_SUCCESS';
export const GET_PANELIST_FAILURE = 'GET_PANELIST_FAILURE';

export const GET_PANELIST_ANALYTICS = 'GET_PANELIST_ANALYTICS';
export const GET_PANELIST_ANALYTICS_SUCCESS = 'GET_PANELIST_ANALYTICS_SUCCESS';
export const GET_PANELIST_ANALYTICS_FAILURE = 'GET_PANELIST_ANALYTICS_FAILURE';

export const EDIT_PANELIST = 'EDIT_PANELIST';
export const EDIT_PANELIST_SUCCESS = 'EDIT_PANELIST_SUCCESS';
export const EDIT_PANELIST_FAILURE = 'EDIT_PANELIST_FAILURE';

export const DELETE_PANELIST = 'DELETE_PANELIST';
export const DELETE_PANELIST_SUCCESS = 'DELETE_PANELIST_SUCCESS';
export const DELETE_PANELIST_FAILURE = 'DELETE_PANELIST_FAILURE';

export const UPDATE_PANELIST_STATUS = 'UPDATE_PANELIST_STATUS';
export const UPDATE_PANELIST_STATUS_SUCCESS = 'UPDATE_PANELIST_STATUS_SUCCESS';
export const UPDATE_PANELIST_STATUS_FAILURE = 'UPDATE_PANELIST_STATUS_FAILURE';

export const GET_AI_SCREENING_QUESTIONS = 'GET_AI_SCREENING_QUESTIONS';
export const GET_AI_SCREENING_QUESTIONS_SUCCESS = 'GET_AI_SCREENING_QUESTIONS_SUCCESS';
export const GET_AI_SCREENING_QUESTIONS_FAILURE = 'GET_AI_SCREENING_QUESTIONS_FAILURE';

export const GET_WHITE_LABEL_DETAILS = 'GET_WHITE_LABEL_DETAILS';
export const GET_WHITE_LABEL_DETAILS_SUCCESS = 'GET_WHITE_LABEL_DETAILS_SUCCESS';
export const GET_WHITE_LABEL_DETAILS_FAILURE = 'CHECK_WHITE_LABEL_FAILURE';

export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_SETTINGS_FAILURE = 'GET_NOTIFICATION_SETTINGS_FAILURE';

export const ADD_DELETE_NOTIFICATION_SETTINGS = 'ADD_DELETE_NOTIFICATION_SETTINGS';
export const ADD_DELETE_NOTIFICATION_SETTINGS_SUCCESS = 'ADD_DELETE_NOTIFICATION_SETTINGS_SUCCESS';
export const ADD_DELETE_NOTIFICATION_SETTINGS_FAILURE = 'ADD_DELETE_NOTIFICATION_SETTINGS_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const GET_ALL_PANELISTS = 'GET_ALL_PANELISTS';
export const GET_ALL_PANELISTS_SUCCESS = 'GET_ALL_PANELISTS_SUCCESS';
export const GET_ALL_PANELISTS_FAILURE = 'GET_ALL_PANELISTS_FAILURE';

export const GET_DROPDOWN_SUBUSER = 'GET_DROPDOWN_SUBUSER';
export const GET_DROPDOWN_SUBUSER_SUCCESS = 'GET_DROPDOWN_SUBUSER_SUCCESS';
export const GET_DROPDOWN_SUBUSER_FAILURE = 'GET_DROPDOWN_SUBUSER_FAILURE';

export const ADD_CUSTOM_FILE = 'ADD_CUSTOM_FILE';
export const ADD_CUSTOM_FILE_SUCCESS = 'ADD_CUSTOM_FILE_SUCCESS';
export const ADD_CUSTOM_FILE_FAILURE = 'ADD_CUSTOM_FILE_FAILURE';

export const GET_ALL_CUSTOM_FIELDS = 'GET_ALL_CUSTOM_FIELDS';
export const GET_ALL_CUSTOM_FIELDS_SUCCESS = 'GET_ALL_CUSTOM_FIELDS_SUCCESS';
export const GET_ALL_CUSTOM_FIELDS_FAILURE = 'GET_ALL_CUSTOM_FIELDS_FAILURE';

export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';
export const DELETE_CUSTOM_FIELD_SUCCESS = 'DELETE_CUSTOM_FIELD_SUCCESS';
export const DELETE_CUSTOM_FIELD_FAILURE = 'DELETE_CUSTOM_FIELD_FAILURE';

export const CREATE_OR_UPDATE_CUSTOM_FIELD = 'CREATE_OR_UPDATE_CUSTOM_FIELD';
export const CREATE_OR_UPDATE_CUSTOM_FIELD_SUCCESS = 'CREATE_OR_UPDATE_CUSTOM_FIELD_SUCCESS';
export const CREATE_OR_UPDATE_CUSTOM_FIELD_FAILURE = 'CREATE_OR_UPDATE_CUSTOM_FIELD_FAILURE';

export const GET_PANELIST_INTERVIEW_STATS = 'GET_PANELIST_INTERVIEW_STATS';
export const GET_PANELIST_INTERVIEW_STATS_SUCCESS = 'GET_PANELIST_INTERVIEW_STATS_SUCCESS';
export const GET_PANELIST_INTERVIEW_STATS_FAILURE = 'GET_PANELIST_INTERVIEW_STATS_FAILURE';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const GET_DASHBOARD_POSITION_LIST = 'GET_DASHBOARD_POSITION_LIST';
export const GET_DASHBOARD_POSITION_LIST_SUCCESS = 'GET_DASHBOARD_POSITION_LIST_SUCCESS';
export const GET_DASHBOARD_POSITION_LIST_FAILURE = 'GET_DASHBOARD_POSITION_LIST_FAILURE';

export const GET_WHITELABEL = 'GET_WHITELABEL';
export const GET_WHITELABEL_SUCCESS = 'GET_WHITELABEL_SUCCESS';
export const GET_WHITELABEL_FAILURE = 'GET_WHITELABEL_FAILURE';

export const GET_RESOURCES = 'GET_RESOURCES';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAILURE = 'GET_RESOURCES_FAILURE';

export const SAVE_EMPLOYER_ROLE = 'SAVE_EMPLOYER_ROLE';
export const SAVE_EMPLOYER_ROLE_SUCCESS = 'SAVE_EMPLOYER_ROLE_SUCCESS';
export const SAVE_EMPLOYER_ROLE_FAILURE = 'SAVE_EMPLOYER_ROLE_FAILURE';

export const GET_EMPLOYER_ROLE = 'GET_EMPLOYER_ROLE';
export const GET_EMPLOYER_ROLE_SUCCESS = 'GET_EMPLOYER_ROLE_SUCCESS';
export const GET_EMPLOYER_ROLE_FAILURE = 'GET_EMPLOYER_ROLE_FAILURE';

export const SUB_EMPLOYER_PASSWORD_RESET = 'SUB_EMPLOYER_PASSWORD_RESET';
export const SUB_EMPLOYER_PASSWORD_RESET_SUCCESS = 'SUB_EMPLOYER_PASSWORD_RESET_SUCCESS';
export const SUB_EMPLOYER_PASSWORD_RESET_FAILURE = 'SUB_EMPLOYER_PASSWORD_RESET_FAILURE';
export const GET_DEEP_LINK_DATA = 'GET_DEEP_LINK_DATA';
export const GET_DEEP_LINK_DATA_SUCCESS = 'GET_DEEP_LINK_DATA_SUCCESS';
export const GET_DEEP_LINK_DATA_FAILURE = 'GET_DEEP_LINK_DATA_FAILURE';

export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';

export const GET_CONFIGURATION_AUTO_INVITE = 'GET_CONFIGURATION_AUTO_INVITE';
export const GET_CONFIGURATION_AUTO_INVITE_SUCCESS = 'GET_CONFIGURATION_AUTO_INVITE_SUCCESS';
export const GET_CONFIGURATION_AUTO_INVITE_FAILURE = 'GET_CONFIGURATION_AUTO_INVITE_FAILURE';

export const FETCH_RESUME_SCORE = 'FETCH_RESUME_SCORE';
export const FETCH_RESUME_SCORE_SUCCESS = 'FETCH_RESUME_SCORE_SUCCESS';
export const FETCH_RESUME_SCORE_FAILURE = 'FETCH_RESUME_SCORE_FAILURE';

export const SET_BOT_VOICE = 'SET_BOT_VOICE';
export const SET_BOT_VOICE_SUCCESS = 'SET_BOT_VOICE_SUCCESS';
export const SET_BOT_VOICE_FAILURE = 'SET_BOT_VOICE_FAILURE';

export const SET_WHITE_LABEL_LOGOS = 'SET_WHITE_LABEL_LOGOS';
export const SET_WHITE_LABEL_LOGOS_SUCCESS = 'SET_WHITE_LABEL_LOGOS_SUCCESS';
export const SET_WHITE_LABEL_LOGOS_FAILURE = 'SET_WHITE_LABEL_LOGOS_FAILURE';

export const FETCH_LOGO = 'FETCH_LOGO';
export const FETCH_LOGO_SUCCESS = 'FETCH_LOGO_SUCCESS';
export const FETCH_LOGO_FAILURE = 'FETCH_LOGO_FAILURE';

export const GET_INTERVIEW_INCBOT = 'GET_INTERVIEW_INCBOT';
export const GET_INTERVIEW_INCBOT_SUCCESS = 'GET_INTERVIEW_INCBOT_SUCCESS';
export const GET_INTERVIEW_INCBOT_FAILURE = 'GET_INTERVIEW_INCBOT_FAILURE';

export const MAKE_POSITION_FROM_INCBOT = 'MAKE_POSITION_FROM_INCBOT';
export const MAKE_POSITION_FROM_INCBOT_SUCCESS = 'MAKE_POSITION_FROM_INCBOT_SUCCESS';
export const MAKE_POSITION_FROM_INCBOT_FAILURE = 'MAKE_POSITION_FROM_INCBOT_FAILURE';

export const CONFIGURATION_PANELISTSLOTS = 'CONFIGURATION_PANELISTSLOTS';
export const CONFIGURATION_PANELISTSLOTS_SUCCESS = 'CONFIGURATION_PANELISTSLOTS_SUCCESS';
export const CONFIGURATION_PANELISTSLOTS_FAILURE = 'CONFIGURATION_PANELISTSLOTS_FAILURE';

export const GET_INCVID_PLATFORM_PERMISSION = 'GET_INCVID_PLATFORM_PERMISSION';
export const GET_INCVID_PLATFORM_PERMISSION_SUCCESS = 'GET_INCVID_PLATFORM_PERMISSION_SUCCESS';
export const GET_INCVID_PLATFORM_PERMISSION_FAILURE = 'GET_INCVID_PLATFORM_PERMISSION_FAILURE';

export const CONNECT_CALENDER = 'CONNECT_CALENDER';
export const CONNECT_CALENDER_SUCCESS = 'CONNECT_CALENDER_SUCCESS';
export const CONNECT_CALENDER_FAILURE = 'CONNECT_CALENDER_FAILURE';

export const CHECK_CALENDAR_CONNECTION = 'CHECK_CALENDAR_CONNECTION';
export const CHECK_CALENDAR_CONNECTION_SUCCESS = 'CHECK_CALENDAR_CONNECTION_SUCCESS';
export const CHECK_CALENDAR_CONNECTION_FAILURE = 'CHECK_CALENDAR_CONNECTION_FAILURE';

export const REVOKE_CALENDAR_CONNECTION = 'REVOKE_CALENDAR_CONNECTION';
export const REVOKE_CALENDAR_CONNECTION_SUCCESS = 'REVOKE_CALENDAR_CONNECTION_SUCCESS';
export const REVOKE_CALENDAR_CONNECTION_FAILURE = 'REVOKE_CALENDAR_CONNECTION_FAILURE';

export const GET_LATEST_ACTIVE_POSITIONS = 'GET_LATEST_ACTIVE_POSITIONS';
export const GET_LATEST_ACTIVE_POSITIONS_SUCCESS = 'GET_LATEST_ACTIVE_POSITIONS_SUCCESS';
export const GET_LATEST_ACTIVE_POSITIONS_FAILURE = 'GET_LATEST_ACTIVE_POSITIONS_FAILURE';

//===========================================Applicant Data=====================================//

export const GET_APPLICANT = 'GET_APPLICANT';
export const GET_APPLICANT_SUCCESS = 'GET_APPLICANT_SUCCESS';
export const GET_APPLICANT_FAILURE = 'GET_APPLICANT_FAILURE';

export const DELETE_APPLICANT = 'DELETE_APPLICANT';
export const DELETE_APPLICANT_SUCCESS = 'DELETE_APPLICANT_SUCCESS';
export const DELETE_APPLICANT_FAILURE = 'DELETE_APPLICANT_FAILURE';

export const UPDATE_APPLICANT = 'UPDATE_APPLICANT';
export const UPDATE_APPLICANT_SUCCESS = 'UPDATE_APPLICANT_SUCCESS';
export const UPDATE_APPLICANT_FAILURE = 'UPDATE_APPLICANT_FAILURE';

export const APPLICANT_SENT_INVITE = 'APPLICANT_SENT_INVITE';
export const APPLICANT_SENT_INVITE_SUCCESS = 'APPLICANT_SENT_INVITE_SUCCESS';
export const APPLICANT_SENT_INVITE_FAILURE = 'APPLICANT_SENT_INVITE_FAILURE';

export const FETCH_SKILLS = 'FETCH_SKILLS';
export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS';
export const FETCH_SKILLS_FAILURE = 'FETCH_SKILLS_FAILURE';

export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_FAILURE = 'FETCH_QUESTIONS_FAILURE';

export const GET_INTERVIEW_DATA = 'GET_INTERVIEW_DATA';
export const GET_INTERVIEW_DATA_SUCCESS = 'GET_INTERVIEW_DATA_SUCCESS';
export const GET_INTERVIEW_DATA_FAILURE = 'GET_INTERVIEW_DATA_FAILURE';

export const EMAIL_NOTIFICATION_CONFIG = 'EMAIL_NOTIFICATION_CONFIG';
export const EMAIL_NOTIFICATION_CONFIG_SUCCESS = 'EMAIL_NOTIFICATION_CONFIG_SUCCESS';
export const EMAIL_NOTIFICATION_CONFIG_FAILURE = 'EMAIL_NOTIFICATION_CONFIG_FAILURE';
export const GET_EMAIL_NOTIFICATION_CONFIG = 'GET_EMAIL_NOTIFICATION_CONFIG';
export const GET_EMAIL_NOTIFICATION_CONFIG_SUCCESS = 'GET_EMAIL_NOTIFICATION_CONFIG_SUCCESS';
export const GET_EMAIL_NOTIFICATION_CONFIG_FAILURE = 'GET_EMAIL_NOTIFICATION_CONFIG_FAILURE';

export const GET_FEEDBACK_REPORT_RESOURCES = 'GET_FEEDBACK_REPORT_RESOURCES';
export const GET_FEEDBACK_REPORT_RESOURCES_SUCCESS = 'GET_FEEDBACK_REPORT_RESOURCES_SUCCESS';
export const GET_FEEDBACK_REPORT_RESOURCES_FAILURE = 'GET_FEEDBACK_REPORT_RESOURCES_FAILURE';

export const POST_APPLICANT = 'POST_APPLICANT';
export const POST_APPLICANT_SUCCESS = 'POST_APPLICANT_SUCCESS';
export const POST_APPLICANT_FAILURE = 'POST_APPLICANT_FAILURE';

export const POST_BULK_APPLICANT = 'POST_BULK_APPLICANT';
export const POST_BULK_APPLICANT_SUCCESS = 'POST_BULK_APPLICANT_SUCCESS';
export const POST_BULK_APPLICANT_FAILURE = 'POST_BULK_APPLICANT_FAILURE';

export const GET_BANNERS = 'GET_BANNERS';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE';

export const SET_MOBILE_DEVICE_PERMISSION = 'SET_MOBILE_DEVICE_PERMISSION';
export const SET_MOBILE_DEVICE_PERMISSION_SUCCESS = 'SET_MOBILE_DEVICE_PERMISSION_SUCCESS';
export const SET_MOBILE_DEVICE_PERMISSION_FAILURE = 'SET_MOBILE_DEVICE_PERMISSION_FAILURE';
